/* @import url(//db.onlinewebfonts.com/c/1a045963159927274c92b0444fb83c17?family=Avenir);
@import url(//db.onlinewebfonts.com/c/1a045963159927274c92b0444fb83c17?family=Avenir); */

.testimonial_wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
    /* overflow-x: auto; */
    padding-left : 80px;

}

.parent_container{
    width : 100%;
    height : 100%;
    overflow: auto;
    padding-top : 60px;
}

.testimonial_wrapper .header_wrapper {
    display: flex;
    flex-basis: 20%;
    width: 90%;
    /* background-color: antiquewhite; */
    align-items: center;
    margin-bottom: 4.5em;
    margin-top: 3em;
    text-align: center;
    justify-content: center;
}

.testimonial_wrapper .header_wrapper p{
  width: 574px;
  height: 49px;
  font-family: Avenir;
  font-size: 32px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: black;
  font-family: Avenir;
  text-align: center;
}

.testimonial_wrapper .box_container {
    display: flex;

    /* flex-basis: 80%; */
    width: auto;
    flex-wrap: nowrap;
    justify-content: space-around;
    margin-top: 1em;
    /* background-color: aqua; */

}

.testimonial_wrapper .box_container .box {
    display: flex;
    flex-direction: column;
    min-width: 600px;
    height: auto;
    padding-bottom : 40px;
    border-radius: 15px;
    border: solid 2px #eaeaea;
    margin-bottom: 4em;
    background-image: url('../../assets/inverted.png');
    background-repeat: no-repeat;
    background-size: 130px 130px;
    margin-right : 30px;
    
    /* opacity: 0.5; */
    
}

.testimonial_wrapper .box_container .box .box_up {
    display: flex;
    flex-direction: column;
    flex-basis: 40%;
    width: 100%;
    /* background-color: aqua; */
    justify-content: center;
    align-items: center;

}

.testimonial_wrapper .box_container .box .box_up .avatar{
    margin-top: -60px;
    width: 120px;
    height: 120px;
    border-radius: 60px;
    /* object-fit: fit; */
    overflow: hidden;
}

.testimonial_wrapper .box_container .box .box_up img{
    margin-top: -60px;
    width: 120%;
    /* height: 120px; */
    border-radius: 60px;
    /* object-fit: fit; */
}

.testimonial_wrapper .box_container .box .box_up .name {
    font-size: 1.2em;
    font-weight: 650;
    line-height: 30px;
    font-family: Avenir;
}

.testimonial_wrapper .box_container .box .box_up .place {
    font-size: 1em;
    color: #b8b6b6;
    line-height: 35px;
    font-weight: 600;
    font-family: Avenir;
}

.testimonial_wrapper .box_container .box .box_down {
    display: flex;
    flex-basis: 60%;
    justify-content: center;
    align-items: center;
    width: 100%;
    /* background-color: blue; */

}

.testimonial_wrapper .box_container .box .box_down .content {
    display: flex;
    height: 100%;
    width: 80%;
    align-items: center;

    /* background-color: aqua; */
}

.testimonial_wrapper .box_container .box .box_down .content p{
    text-align: left;
    font-size: 0.9em;
    font-weight: 550;
    letter-spacing: 1px;
    color: #55565b;
    margin-top: 1em;
    font-family: Avenir;
    /* padding : 20px 0px; */
}

@media(max-width : 576px) {
    .header_wrapper{
        width : 100%;
        text-align: center;
        padding : 20px 0px;
    }
}