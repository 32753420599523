.signup_wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.signup_wrapper .signup_header {
    display: flex;
    flex-basis: 5%;
    /* background-color: blue; */
    margin: 10px 30px 10px 20px;
    justify-content: center;
    font-size: 20px;
}

.signup_wrapper .signup_content {
    display: flex;
    flex-direction: column;
    flex-basis: 95%;
    margin: 10px 40px 10px 40px;
    /* background-color: blue; */
}

.signup_wrapper .signup_content .signup_input {
    display: flex;
    flex-direction: column;
    width: 100%;
    /* height: 100%; */
    /* background-color: blue; */
}

.signup_wrapper .signup_content .signup_input label {
    margin-top: 40px;
}

.signup_wrapper .signup_content .signup_input .flabel {
    margin-top: 40px;
}

.signup_wrapper .signup_content .signup_input label input {
    border-top: none;
    border-bottom:1px solid rgb(13, 114, 13);
    border-left: none;
    border-right: none;
    width: 80%;
    margin-top: 8px;
}

.signup_wrapper .signup_content .signup_input label input:focus {
    outline: none;
}

.signup_wrapper .signup_content .btn_wrapper {
    display: flex;
    width: 100%;
    height: 100%;
    margin-top: 25px;
    justify-content: center;
    align-items: center;
    padding-bottom: 10px;
    margin-bottom: 10px;
    padding-top: 10px;
}

.signup_wrapper .signup_content .btn_wrapper .btn {
    background-color: #00b246;
    width: 335px;
    height: 47px;
    border-radius: 5px;
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.2);
    color : white;
    font-family: 'Avenir Heavy', 'Roboto', sans-serif;
    font-size : 0.8rem;
    border: none;
}

.signup_wrapper .signup_content .btn_wrapper .btn:focus {
    outline: none;
}

.signup_wrapper .signup_content .btn_wrapper .btn:hover {
    outline: none;
    cursor: pointer;
}