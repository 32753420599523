.Container {
  padding: 20px;
  height: auto;
  border-radius: 8px;
  -webkit-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.165);
          box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.165);
  background-color: white;
}

.Container .ActiveTop {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 3rem;  
  position: relative;      
}

.Container .ActiveTop.Proceed:before {
  content: "";
  background-color: #CCF0DA;
  width: 4px;
  height: 3.2rem;
  top: 27px;
  position: absolute;
  left: 3%;
}

.Container .ActiveTop .OuterCircleActive {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: rgba(0, 178, 70, 0.2);
}

.Container .ActiveTop .OuterCircle {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color:  rgba(0, 178, 70, 0.2);
}

.Container .ActiveTop .InnerCircleActive {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: solid 3px #00b246;
  background-color: #00b246;
}

.Container .ActiveTop .InnerCircle {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: solid 3px #00b246;
  background-color: white;
}

.Container .ActiveTop .OrderStatus {
  font-size: 1rem;
  font-family: 'Avenir Heavy', 'Roboto', sans-serif;
  width: 190px;
  margin-left: 15px;
}

.Container .ActiveTop .ButtonTrack {
  border: none;
  padding: 8px 20px;
  border-radius: 5px;
  color: #00b246;
  font-family: 'Avenir Heavy', 'Roboto', sans-serif;
}

.Container .Details {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 20px 0px;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  font-size: 0.9rem;
  font-family: 'Avenir Roman';
}

.Container .Details .Left {
  width: 150px;
}

.Container .Details .Left .Location {
  color: #55565b;
}

.Container .Details .Right {
  width: 100px;
  font-size: 1rem;
  font-family: 'Avenir Heavy', 'Roboto', sans-serif;
}

.Container .Details .Right .Remaining {
  color: #55565b;
}

.Container .HRLine {
  width: 100%;
  height: 1px;
  background-color: #55565b25;
  margin: 10px 0px;
  clear: both;
  display: inline-block;
}

.Container .Payment {
  padding: 5px 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.Container .Payment .Left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.Container .Payment .Left .Paid {
  color: #999999;
}

.Container .Payment .Left .Amount {
  font-family: 'Avenir Heavy', 'Roboto', sans-serif;
}

.Container .Payment .Right {
  color: #0e4d97;
}

.Container .Delivery {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin-top: 10px;
}

.Container .Delivery .Left {
  width: 250px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.Container .Delivery .Left .Image {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.Container .Delivery .Left .Image img {
  height: 80px;
}

.Container .Delivery .Left .Profile {
  margin-left: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.Container .Delivery .Left .Profile .Role {
  font-size: 0.8rem;
  color: #55565b;
}

.Container .Delivery .PhoneIcon {
  margin-right: 20px;
}

.Container .SupportCustomer {
  font-family: 'Avenir Heavy', 'Roboto', sans-serif;
  font-size: 1.2rem;
  color: #00b246;
}

.Container .SectionTitle , .Container .SectionDetails {
  margin-bottom: 1rem;
}

.Container .SectionDetails .Left {
  width: 80%;
  float: left;
}

.Container .SectionDetails .Left .ItemDescription {
  color: #999999;
  font-weight: 400;
  margin: 10px 0;
  display: block;
}

.Container .SectionDetails .Right {
  width: 20%;
  float: left;
  text-align: right;
}

.Container .SectionDetails .Row .Left, .Container .SectionDetails .Row .Right {
  margin: 1rem 0;
}
.Container .SectionDetails .Row .Right { 
  color: #999999;
}


@media (max-width: 576px) {
  .Container {
    width: 100%;
    margin-right: 0px;
  }
}
/*# sourceMappingURL=ActiveOrderCard.module.css.map */