

.ques_container {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
}

.ques_container .quesArea {
    display: flex;
    width: 90%;
    height: 90%;
    margin-top: 10px;
    justify-content: space-between;

}

.ques_container .quesArea .left {
    display: flex;
    flex-direction: column;
    flex-basis: 50%;
    height: 100%;

}

.ques_container .quesArea .left .heading {
    font-weight: 600;
    font-size: 15px;
    
}

.ques_container .quesArea .left .text {
    font-weight: 500;
    font-size: 13px;
    color:#737374;
    margin-top: 20px;
    padding-right: 40px;
    letter-spacing: 0.5px;
    
}


.ques_container .quesArea .right {
    display: flex;
    flex-basis: 50%;
    margin-left: 40px;
  
    height: 100%;
    
}

.ques_container .quesArea .right .content_wrapper {
    display: flex;
    height: 90%;
    width: 70%;
    background-color: #f5f5f5;
    justify-content: center;
    align-items: center;

}

.ques_container .quesArea .right .content_wrapper .content {
    display: flex;
    flex-direction: column;
    height: 90%;
    width: 90%;
}

.ques_container .quesArea .right .content_wrapper .content .heading {
    font-weight: 600;
    font-size: 14px;
    color: black;
    text-decoration: none;
}

.ques_container .quesArea .right .content_wrapper .content p {
    font-weight: 500;
    font-size: 13px;
    color: #999999;
    margin-top: 10px;
    cursor: pointer;
    text-decoration: underline;
}

@media (max-width: 586px) {
    .ques_container .quesArea {
        flex-direction: column;
    
    }

    .ques_container .quesArea .left .heading {
        font-weight: 600;
        font-size: 15px;
        margin-top: 15px;
        
    }

  

    .ques_container .quesArea .right .content_wrapper {
        display: flex;
        height: 90%;
        width: 100%;
        margin-top: 20px;
        
    
    }
}