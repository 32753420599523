.header {
    display: flex;
    height: 10vh;
    width: 100%;
}

.main_container {
    display: flex;
    height: 100vh;
    width: 100%;
    /* background-color: blue; */
}

.main_container .left {
    display: flex;
    height: 100%;
    flex-basis: 30%;
    background-color: #00b246;
    justify-content: center;
    align-items: center;
}

.main_container .left .box {
    display: flex;
    flex-direction: column;
    height: 80%;
    width: 400px;
    border-radius: 13px;
    box-shadow: 0 8px 31px 0 rgba(96, 100, 112, 0.1);
    border: solid 1px var(--white-two);
    background-color: #ffffff;
    margin-right: -50%;
    position : relative;
    z-index: 10;
}

.main_container .left .box .header {
    width: 100%;
    display: flex;
    align-items: center;
    height: 10%;
    border-bottom: 2px solid #eaeaea;
    /* background-color: blue; */
    /* color: white; */
}

.main_container .left .box p{
    font-size: 23px;
    color: #999999;
    font-weight: 500;
    line-height: 30px;
    margin-left: 15px;
    margin-top: 15px;
}

.main_container .left .box .bottom_section {
    display: flex;
    width: 100%;
    margin-top: auto;
    margin-bottom: 30px;
    height: 50px;
    justify-content: center;
    align-items: center;
    /* background-color: black; */

}

.main_container .left .box .bottom_section .referral_container {
    display: flex;
    width: 90%;
    border: 2px solid #eaeaea;
    border-radius: 5px;
    height: 95%;
    justify-content: space-between;
    align-items: center;
}

.main_container .left .box .bottom_section .referral_container p{
    color: black;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 10px;
}

.main_container .left .box .bottom_section .referral_container button {
    width: 80px;
    height: 35px;
    border-radius: 7px;
    background-color: #00b246;
    border: none;
    color: white;
    margin-right: 10px;
}

.main_container .right {
    display: flex;
    height: 100%;
    flex-basis: 70%;
    justify-content: center;
    align-items: center;
}

.main_container .right .content {
    display: flex;
    height: 90%;
    width: 400px;
    flex-direction: column;
    /* justify-content: center; */
    /* align-items: center; */
    /* background-color: blue; */
}

.main_container .right .content img {
    height: 70%;
}

.main_container .right .content .heading {
    font-size: 19px;
    color: #55565b;
    font-weight: 700;
    padding: 10px;
}

.main_container .right .content .label {
    font-size: 17px;
    color:#55565b ;
    padding: 10px;
}

.main_container .right .content .btn_wrapper {
    display: flex;
}

@media(max-width : 576px) {
    .main_container {
        display: flex;
        flex-direction: column;
        height: auto;
        width: 100%;
        /* background-color: blue; */
    }

    .main_container .left .box {
        height: 80%;
        width: 90%;
        margin-right: 0;
        margin-bottom: -20%;
        z-index: 1000;
        margin-top: 10%;
    }

    .main_container .left .box .bottom_section .referral_container p{

        font-size: 13px;
        
    }

    .main_container .right {
        height: auto;
        justify-content: center;
        align-items: center;
        margin-top: 10%;
    }

    .main_container .right .content {
        height: auto;
        width: 90%;
        justify-content: center;
        align-items: center;
        /* background-color: blue; */
    }

    .main_container .right .content img {
        height: 50%;
        width: 100%;
    }

    .main_container .right .content .btn_wrapper {
        margin-bottom: 10px;
        padding-bottom: 20px;
    }

    .main_container .right .content .btn_wrapper img {
        width: 130px;
    }
}