.nav_wrapper {
    display: flex;
    width: 100%;
    height: 20vh;
}

.order_wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 80vh;
}

.order_wrapper .payment_status {
    display: flex;
    width: 100%;
    height: 15%;
    /* height: 100%; */
    justify-content: center;
    align-items: center;
    /* background-color: aqua; */
}

.order_wrapper .order_status {
    display: flex;
    width: 100%;
    height: 50%;
    /* background-color: black; */

}

.order_wrapper .order_status .left {
    display: flex;
    flex-basis: 50%;
    height: 100%;
}

.order_wrapper .order_status .left .box {
        display: flex;
        flex-direction: column;
        width: 400px;
        height: 400px;
        border-radius: 7px;
        box-shadow: 0 3px 11px 0 rgba(0, 0, 0, 0.13);
        border: solid 1.4px var(--white-two);
        background-color: #ffffff;
        z-index: 1000;
        margin-left: auto;
        justify-content: center;
        align-items: center;
        /* margin-right: -10%; */

}

.order_wrapper .order_status .left .box .content_box {
    display: flex;
    flex-direction: column;
    width: 93%;
    height: 90%;
    /* background-color: blue; */
    
}

.order_wrapper .order_status .left .box .content_box .container {
    display: flex;
    width: 100%;
    height: 15%;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    /* background-color: blue; */
}

.order_wrapper .order_status .left .box .content_box .container button {
    border: none;
    width: 60%;
    height: 40px;
    color: white;
    background-color: #00b246;
    border-radius: 5px;
}

.order_wrapper .order_status .left .box .content_box .container .track {
    display: flex;
    width: 158px;
    height: 42px;
    border-radius: 7px;
    justify-content: center;
    align-items: center;
    background-color: #f4f4f4;
    color: #00b246;
    cursor: pointer;
}

.order_wrapper .order_status .left .box .content_box .container .left {
    display: flex;
    flex-direction: column;
}

.order_wrapper .order_status .left .box .content_box .container .right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.order_wrapper .order_status .right {
    display: flex;
    flex-basis: 50%;
    height: 100%;
    flex-direction: column;
    /* justify-content: center; */
    /* align-items: center; */
}

.order_wrapper .order_status .right .box {
    display: flex;
    flex-direction: column;
    height: 300px;
    width: 350px;
    margin-left: 50px;
    /* background-color: black; */
}

.order_wrapper .order_status .right .box .heading {
    font-weight: 900;
    font-size: 20px;
    padding: 10px;
}

.order_wrapper .order_status .right .box .label {
    padding: 10px;
    font-size: 20px;
    font-weight: 500;
    color: #626262;
}

.order_wrapper .order_status .right .box .btn_wrapper {
    display: flex;
    width: 100%;
}

.order_wrapper .bottom_section {
    display: flex;
    height: 35%;
    background-image: url('../../assets/footerimg2.png');
    
}

@media(max-width : 576px) {
    .order_wrapper .order_status {
        flex-direction: column;
        height: auto;
        /* background-color: black; */
    
    }

    .order_wrapper .order_status .left {
       height: auto;
       justify-content: center;
       align-items: center;
    }

    .order_wrapper .order_status .left .box {
         width: 95%;
        height: 400px;
        margin-left: 0;
        margin-top: 15px;
       
        /* margin-right: -10%; */

}

    .order_wrapper .order_status .right {
        height: auto;
        justify-content: center;
        align-items: center;
    }

    .order_wrapper .order_status .right .box .btn_wrapper img {
        width: 100px;
        
    }
}