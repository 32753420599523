.Modal_Styles {
  position: fixed;
  top: 50%;
  left: 50%;
  height: auto;
  width: 30%;
  border-radius: 10px;
  transform: translate(-50%, -50%);
  background-color: #fff;
  z-index: 1000;
  display: flex;
  flex-direction: column;
}

.login_wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  /* background-color: blue; */
}

.login_wrapper .login_header {
  display: flex;
  flex-basis: 5%;
  /* background-color: blue; */
  margin: 10px 20px 10px 20px;
  justify-content: center;
  font-size: 20px;
}

.login_wrapper .login_content {
  display: flex;
  flex-direction: column;
  flex-basis: 95%;
  margin: 10px 20px 10px 20px;
  align-items: center;
  /* background-color: blue; */
}

.login_wrapper .login_content .login_content_header {
  display: flex;
  height: 30%;
  width: 90%;
  margin: 5px 10px 5px 10px;
  /* background-color: blue; */
}

.login_wrapper .login_content .login_content_header .left {
  display: flex;
  flex-direction: column;
  flex-basis: 50%;
  height: 100%;
  /* background-color: #97d700; */
  color: #999999;
  font-size: 15px;
}

.login_wrapper .login_content .login_content_header .left img {
  height: 20%;
  width: 70%;
  margin-left: -5px;
}

.login_wrapper .login_content .login_content_header .right {
  display: flex;
  flex-basis: 50%;
  height: 100%;
}

.login_wrapper .login_content .login_content_header .right img {
  height: 220px;
  width: 220px;
}

.login_wrapper .login_content .inputArea {
  display: flex;
  flex-direction: column;
  height: 40%;
  width: 70%;
  /* background-color: #19489e; */
  /* height: 100%; */
  /* justify-content: center; */
  /* align-items: center; */
}

.login_wrapper .login_content .inputArea button {
  margin-top: 30px;
  color: white;
  border: none;
  background-color: #00b246;
  border-radius: 5px;
  font-family: "Avenir Heavy", "Roboto", sans-serif;
  padding: 12px 10px;
  font-size: 0.8rem;
}

.login_wrapper .login_content .inputArea label {
  display: flex;
  flex-direction: column;
  margin-top: 60px;
}

.login_wrapper .login_content .inputArea label input {
  border-top: none;
  border-bottom: 1px solid green;
  border-left: none;
  border-right: none;
  width: 100%;
  margin-top: 5px;
}

.login_wrapper .login_content .inputArea .otp {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
}

.login_wrapper .login_content .inputArea .otp input {
  border: 1px solid green !important;
  margin-top: 10px;
}

.login_wrapper .login_content .inputArea .otp .otpInput input {
  width: 60%;
  /* padding : 2px 10px; */
}

.login_wrapper .login_content .inputArea .otp .otpInput .s_input {
  margin-left: 10px;
}

.login_wrapper .login_content .inputArea label input:focus {
  outline: none;
}

.login_wrapper .login_content .footer {
  display: flex;
  flex-basis: 10%;
  /* background-color: blue; */
  width: 90%;
  margin-top: 40px;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.login_wrapper .login_content .footer img {
  width: 170px;
  height: 80%;
  margin-right: 10px;
}

.login_wrapper .login_content .footer img:hover {
  cursor: pointer;
  transform: translateY(-1px);
}

button {
  cursor: pointer;
}
.Overlay_Styles {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1000;
}

@media (max-width: 768px) {
  .Modal_Styles {
    width: 100%;
    height: 100%;
    border-radius: 0;
  }

  .login_wrapper .login_content .login_content_header .right img {
    height: 150px;
    width: 150px;
  }

  .login_wrapper .login_content .footer {
    display: block;
    width: 70%;
  }

  .login_wrapper .login_content .footer img {
    width: 100%;
    height: auto;
    margin-left: 5px;
  }
}
