.container {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
}

.container .header {
    display: flex;
    width: 100%;
    height: 30%;
    /* background-color: #00b246; */
    justify-content: center;
    align-items: center;
}

.container .header .header_content {
    display: flex;
    flex-direction: column;
    width: 90%;
    height: 70%;
    justify-content: space-around;
}

.container .header .header_content .heading {
    color: #ffffff;
    font-size: 22px;
    font-weight: 500;
    letter-spacing: 1px;
}

.container .header .header_content .inputArea {
    display: flex;
    width: 100%;
    height: 50px;
    background-color: white;
    border-radius: 5px;
    align-items: center;
  
}

.container .header .header_content .inputArea input {
    height: 90%;
    width: 60%;
    border: none;
    padding: 10px;
}

.container .header .header_content .inputArea input:focus {
    outline: none;
}

.container .category_box {
    display: flex;
    width: 100%;
    height: 115px;
    /* background-color: black; */
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.container .category_box .content {
    display: flex;
    width: 90%;
    height: 100%;
    justify-content: space-between;
    align-items: center;

}

.container .category_box .content .text {
    font-size: 18px;
    font-weight: 550;
}

.container .category_box .content .text_second {
    font-size: 17px;
    font-weight: 550;
    color: #999999;
}

@media(max-width : 576px) {

    .container .header .header_content .heading {
        font-size: 17px;
        margin-bottom: 10px;
        margin-top: 15px;
        padding-bottom: 10px;
       
    }

    .container .header .header_content .inputArea {
        height: 50px;
        margin-bottom: 10px;
      }
}