.Container {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.Container .Main {
  width: 100%;
  background-color: #fafbfc;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.Container .Main .Contact {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 3% auto 6% auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.Container .Main .Contact .InfoPanel {
  width: 70%;
  margin-top: 40px;
  border-radius: 8px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.Container .Main .Contact .InfoPanel .Left {
  width: 45%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-right: 5%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.Container .Main .Contact .InfoPanel .Right {
  width: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.Container .Main .Contact .InfoPanel .Left .OrderDetails {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.Container .Main .Contact .InfoPanel .Left .Heading {
  color: #55565b;
  margin-top: 40px;
  font-family: 'Avenir';
  margin-bottom: 20px;
  width: 100%;
}

.Container .Main .Contact .InfoPanel .Left .Info {
  font-family: 'Avenir Heavy', 'Roboto', sans-serif;
  width: 100%;
}

@media (max-width: 576px) {
  .Container .Main {
    height: auto;
  }
  .Container .Main .Contact {
    -webkit-transform: translateY(-70px);
            transform: translateY(-70px);
  }
  .Container .Main .Contact .InfoPanel {
    width: 95%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    padding: 20px;
  }
  .Container .Main .Contact .InfoPanel .Left {
    width: 100%;
  }
  
  .Container .Main .Contact .InfoPanel .Right {
    width: 100%;
    padding: 10px;
    height: 300px;
  }
}
/*# sourceMappingURL=ContactUs.module.css.map */