.coupon_wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: center;
}

.coupon_wrapper .coupon_header {
    display: flex;
    flex-basis: 5%;
    font-size: 17px;
    width: 90%;
    justify-content: space-between;
    align-items: center;
    padding-top: 10px;
    /* background-color: blue; */
}

.coupon_wrapper .inputArea {
    display: flex;
    flex-basis: 20%;
    width: 90%;
    /* background-color: blue; */
    align-items: center;
    padding-bottom: 15px;
    padding-top: 20px;
}

.coupon_wrapper .inputArea .input_container {
    display: flex;
    width: 100%;
    height: 55px;
    border-radius: 7px;
    border: solid 1.4px #eaeaea;
    background-color: #fefefe;
    justify-content: space-between;
    align-items: center;
 
}

.coupon_wrapper .inputArea .input_container input {
    height: 100%;
    border: none;
    width: 60%;
    padding: 10px;
}

.coupon_wrapper .inputArea .input_container input:focus {
    outline: none;
}

.coupon_wrapper .inputArea .input_container p {
    color: #00b246;
    padding-right: 3%;
    font-size: 10px;
    cursor: pointer;
    width : 20%;
    text-align: right;
}

.coupon_wrapper .divider {
    width: 100%;
    background-color: #eaeaea;
    padding-bottom: 7px;
    margin-bottom: 10px;
}

.coupon_wrapper .container {
    display: flex;
    flex-direction: column;
    width: 90%;
    padding-bottom: 15px;
    padding-top: 15px;
    overflow-y: scroll;
    height: 500px;
    
}

.coupon_wrapper .container::-webkit-scrollbar {
    border-radius: 5px;
    width: 1em;
    
}

.coupon_wrapper .container p{
    font-weight: 900;
    font-size: 17px;
    padding-bottom: 20px;
}

.coupon_wrapper .container .box {
    display: flex;
    width: 100%;
    height: auto;
    border-radius: 7px;
    box-shadow: 0 3px 11px 0 rgba(0, 0, 0, 0.13);
    border: solid 1.4px #eaeaea;
    background-color: #ffffff;
    /* align-items: center; */
    justify-content: center;
    margin-top: 15px;
}

.coupon_wrapper .container .box .content {
    display: flex;
    flex-direction: column;
    width: 90%;
    height: 100%;
    align-items: center;
    /* justify-content: center; */

}

.coupon_wrapper .container .box .content .up {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    padding-top: 10px;
    /* background-color: blue; */
    align-items: center;

}

.coupon_wrapper .container .box .content .up .coupon_code{
    display: flex;
    width: 120px;
    height: 40px;
    background-color: rgba(255, 188, 0, 0.2);
    justify-content: center;
    border: 1px dotted #ffbc00;
    border-style: dotted;
    border-radius: 5px;
    /* align-items: center; */
  
    
}
.coupon_wrapper .container .box .content .up .coupon_code p{
    font-weight: 500;
    margin-top: 7px;
    

}

.coupon_wrapper .container .box .content .down {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-top: 15px;
    margin-top: 10px;

}

.coupon_wrapper .container .box .content .down p{
    font-size: 17px;
    font-weight: 900;
    color: black;
}

.coupon_wrapper .container .box .content .down .down_content p{
    font-weight: 400;
    color: #9b9999;
    font-size: 15px;
}



